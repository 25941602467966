import React, { useContext, useEffect } from 'react'
import { graphql, PageProps, useStaticQuery } from 'gatsby'
import { SearchContext } from '../contexts/searchContext'
import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core/styles'
import { Layout } from '../component/Layout'
import { PropertyList } from '../component/page_component/PropertyList'
import { useIntl } from 'gatsby-plugin-intl'
import hreflangConfig from '../utils/hreflangConfig'
import { Helmet } from 'react-helmet'

const useStyles = makeStyles((theme) => ({
  filterContainer: {
    backgroundColor: '#FAE9E5',
  },
}))

const Rent: React.FC<PageProps> = (props: any) => {
  const intl = useIntl()
  const {
    location,
    pageContext: { data },
  } = props
  const classes = useStyles()
  const { setSearch } = useContext(SearchContext)

  useEffect(() => {
    setSearch({ name: 'transactionType', value: 'rent' })
  }, [])

  const hreflangLinks = hreflangConfig['/fr/biens-en-location/']
  const dataCanonical = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          siteUrl
        }
      }
    }
  `)
  const canonicalUrl = `${dataCanonical.site.siteMetadata.siteUrl}${location.pathname}`
  return (
    <Layout location={location} title={intl.formatMessage({ id: 'PageTitleRent' })}>
      <Helmet>
        <link rel="canonical" href={canonicalUrl} />
        {hreflangLinks &&
          Object.keys(hreflangLinks).map((lang) => (
            <link key={lang} rel="alternate" hrefLang={lang} href={hreflangLinks[lang]} />
          ))}
        <meta name="description" content="Louer avec nous - Kretz" />
      </Helmet>
      <Grid container className={classes.filterContainer} id="search-for-rental"></Grid>
      <PropertyList
        bgClr="#FAE9E5"
        filterBackgroundColor={'#FFF'}
        location={location}
        data={data}
      />
    </Layout>
  )
}

export default Rent
