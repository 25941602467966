import React from 'react'
import Box from '@material-ui/core/Box'
import Link from '@material-ui/core/Link'
import Carousel from 'react-multi-carousel'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import styled from 'styled-components'
import { DetailContentBox } from './DetailBox/DetailContentBox'
import CustomArrow from './page_component/Display/CustomArrow'
import { useIntl } from 'gatsby-plugin-intl'

const responsive = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 3000 },
    items: 1,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 1,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 1,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
}

const useStyles = makeStyles((theme) => ({
  root: {},
  head: {
    fontSize: '0.8rem',
    marginBottom: theme.spacing(2),
  },
  title: {
    fontSize: '2rem',
    marginBottom: theme.spacing(2),
  },
  specific: {
    fontSize: '0.9rem',
    marginBottom: theme.spacing(5),
    [theme.breakpoints.between('xs', 'sm')]: {
      marginBottom: theme.spacing(2),
    },
  },
  detail: {
    height: '100%',
    fontSize: '0.8rem',
    textAlign: 'left',
  },
  price: {
    fontSize: '1.4rem',
    marginBottom: theme.spacing(3),
    [theme.breakpoints.between('xs', 'sm')]: {
      fontSize: '1.2rem',
      marginBottom: theme.spacing(1),
    },
  },
  launch: {
    fontSize: '0.8rem',
  },
  img: {
    position: 'absolute',
    bottom: 0,
    margin: 'auto',
    left: '50%',
    transform: 'translate(-50%)',
    height: '115%',
    width: '100%',
    objectFit: 'cover',
  },
}))

const DetailsBoxDiv = styled(Grid)`
  background: white;
  width: 40rem;
  height: 100%;
  padding-top: .8rem;
  padding-bottom: .8rem;
  padding-left: 1.8rem;
  padding-right: 1.8rem;
  max-width: 100%;
  transition: all ease 0.2s;
  transform: translateY(0rem);

  @media (max-width: 700px) {
    width: 50rem;
    padding-left: 1.8rem;
    padding-right: 1.8rem;
    max-width: 100%;
  }
  @media (min-width: 1400px) {
    .btnTxt {
      opacity: 0;
      height: 0;
      transition: all ease 0.2s;
      padding-left: .8rem;
      padding-right: .8rem;
    }

    &:hover {
      transform: translateY(-3rem);
      .btnTxt {
        opacity: 1;
      }
    }
  }
`

const Main = styled.div`
  width: 35rem;
  max-width: 90vw;
  height: 46rem;
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  margin: auto;
  @media (max-width: 700px) {
    height: unset;
  }
  .custom-dot-list {
    button {
      width: 45px;
      height: 4px;
      border-radius: unset;
      margin: 20px 5px;
      border-width: 1px;
    }

    .react-multi-carousel-dot button {
      background: #2121214f;
    }
    .react-multi-carousel-dot--active button {
      background: #fff;
    }
  }
  .react-multi-carousel-track {
    height: 370px;
  }
  .react-multi-carousel-item {
    overflow: hidden;
  }
`

interface PropertyShowcaseProps {
  area: string
  confidentialPrice: boolean
  details: string
  isOffmarket: boolean
  isRented: boolean
  isSeasonal: boolean
  pieces: string
  price: string
  priceMax: string
  propertyTypeSlug: string
  reference: string
  showTo?: boolean
  size: string
  src: string[]
  suiviPar: any
  title: string
  unitArea: string
  unitPrice: string
  ville: string
}

export const PropertyShowcase: React.FC<PropertyShowcaseProps> = (props) => {
  const { src } = props
  const intl = useIntl()
  const classes = useStyles()
  return (
    <Main className={classes.root}>
      <Box>
        <Carousel
          responsive={responsive}
          infinite
          autoPlay={false}
          showDots={!props.isOffmarket}
          customRightArrow={!props.isOffmarket ? <CustomArrow side="right" height="100%" /> : <></>}
          customLeftArrow={!props.isOffmarket ? <CustomArrow side="left" height="100%" /> : <></>}
          dotListClass="custom-dot-list"
        >
          {src.map((each, index) => ((!props.isOffmarket || index == 0) && (
            <Link
              href={!props.isOffmarket ?
                ('/' +
                intl.locale +
                '/annonce/' +
                props.reference.toLowerCase() +
                '/' +
                props.propertyTypeSlug.toLowerCase() +
                '/') :
                undefined
              }
              underline="none"
            >
              <img key={index} src={each} alt="Details" className={classes.img} loading="lazy" />
            </Link>
          )))}
        </Carousel>
      </Box>
      <Box className={classes.detail}>
        <DetailsBoxDiv>
          <DetailContentBox {...props} />
        </DetailsBoxDiv>
      </Box>
    </Main>
  )
}
