import React, { useContext } from 'react'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import { ImportExportTwoTone } from '@material-ui/icons'

import { useIntl } from 'gatsby-plugin-intl'
import { makeStyles } from '@material-ui/core/styles'
import { SearchContext } from '../../contexts/searchContext'

const useStyles = makeStyles((theme) => ({
  menuItem: {
    marginTop: theme.spacing(6),
  },
  btn: {
    textTransform: 'capitalize',
  },
}))

const PropertySort = () => {
  const classes = useStyles()
  const { setSearch } = useContext(SearchContext)

  const intl = useIntl()
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleSort = (value: string) => () => {
    setSearch({ name: 'sort', value })
    setAnchorEl(null)
  }

  return (
    <Box>
      <Button
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={handleClick}
        className={classes.btn}
      >
        <ImportExportTwoTone /> {intl.formatMessage({ id: 'sort' })}
      </Button>
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
        PopoverClasses={{ root: classes.menuItem }}
      >
        <MenuItem onClick={handleSort('ascPrice')}>
          {intl.formatMessage({ id: 'ascPrice' })}
        </MenuItem>
        <MenuItem onClick={handleSort('descPrice')}>
          {intl.formatMessage({ id: 'descPrice' })}
        </MenuItem>
        <MenuItem onClick={handleSort('ascArea')}>{intl.formatMessage({ id: 'ascArea' })}</MenuItem>
        <MenuItem onClick={handleSort('descArea')}>
          {intl.formatMessage({ id: 'descArea' })}
        </MenuItem>
      </Menu>
    </Box>
  )
}

export default PropertySort
