import React from 'react'
import { makeStyles, Theme } from '@material-ui/core/styles'
import CloseIcon from '@material-ui/icons/Close'

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    fontFamily: `'Gotham Book', serif`,
    fontSize: theme.typography.pxToRem(13.8),
    backgroundColor: ({ backgroundColor }) => backgroundColor,
    marginRight: theme.spacing(2),
    marginBottom: theme.spacing(1),
    color: ({ color }) => color,
    padding: '1em',
    position: 'relative',
    maxWidth: '100%',
  },
  text: {
    paddingRight: '1.5em',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    display: 'block',
    width: '100%',
  },
  svg: {
    position: 'absolute',
    right: '1em',
    top: '.5em',
    background: 'transparent',
    boxShadow: '0px 0px 0px transparent',
    border: '0px solid transparent',
    textShadow: '0px 0px 0px transparent',
  },
}))

interface CancelBadgeProps {
  text: string
  id: string | number
  backgroundColor?: string
  color?: string
  onRemove: (id: string | number) => void
}

export const CancelBadge: React.FC<CancelBadgeProps> = (props) => {
  const { text, onRemove, id } = props
  const classes = useStyles(props)
  return (
    <div className={classes.root}>
      <span className={classes.text}>{text}</span>
      <button className={classes.svg} onClick={() => onRemove(id)}>
        <CloseIcon fontSize="inherit" />
      </button>
    </div>
  )
}

export default CancelBadge
